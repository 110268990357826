import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
  CanDeactivate,
  CanLoad,
  Route,
  UrlSegment,
  UrlTree,
} from "@angular/router";
import { AuthenticationService } from "../services/authentication.service";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {
  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    let url: string = state.url;
    return this.checkUserLogin(next, url);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    return this.canActivate(next, state);
  }

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): boolean | UrlTree {
    return true;
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    return this.authService.isAuthenticated();
  }

  checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean | UrlTree {
    if (this.authService.isAuthenticated()) {
      const role = this.authService.currentUserValue?.role?.name;
      if (role && route.data && route.data.role && route.data.role.indexOf(role) === -1) {
        return this.router.parseUrl("/home");
      }
      return true;
    } else {
      return this.router.parseUrl("/account/login");
    }
  }
}
