import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userRole: string | null = null;
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem("authenticatedUser") || 'null')
    );
    this.currentUser = this.currentUserSubject.asObservable();

    // Retrieve the role from localStorage when service is initialized
    const storedRole = JSON.parse(localStorage.getItem('userRole') || 'null');
    if (storedRole) {
      this.userRole = storedRole; // Set the role in service
    }
  }

  setUserRole(role: string) {
    this.userRole = role;
    localStorage.setItem('userRole', JSON.stringify(role)); // Store role in localStorage as string
    console.log(`Setting Role in AuthService: ${role}`);
  }

  public getUserRole(): string | null {
    return this.userRole;
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }
}