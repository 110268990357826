import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { Users } from "../models/users";
import { baseURL } from "../constants/base.url";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})

export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<Users | null>;
  public currentUser: Observable<Users | null>;

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<Users | null>(
      JSON.parse(localStorage.getItem('authenticatedUser') || 'null')
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): Users | null {
    return this.currentUserSubject.value;
  }

  public getCurrentUserToken(): string | null {
    const currentUser = JSON.parse(localStorage.getItem('authenticatedUser') || 'null');
    return currentUser ? currentUser.token : null;
  }

  login(credentials: { email: string; password: string }): Observable<any> {
    return this.http.post<any>(`${baseURL}users/login`, credentials).pipe(
      map(response => {
        if (response && response.token) {
          localStorage.setItem('authToken', response.token);
          localStorage.setItem('authenticatedUser', JSON.stringify(response.data.user));

          // Store the role in localStorage in string format
          localStorage.setItem('userRole', JSON.stringify(response.data.user.role)); 

          this.currentUserSubject.next(response.data.user);
        }
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getToken(): string | null {
    return localStorage.getItem('authToken');
  }

  logout(): void {
    localStorage.removeItem('authenticatedUser');
    localStorage.removeItem('authToken');

    // Remove the role from localStorage on logout
    localStorage.removeItem('userRole'); 

    this.currentUserSubject.next(null);
    this.router.navigate(['/account/login']);
  }

  isAuthenticated(): boolean {
    const token = this.getToken();
    return !!token; // Returns true if token exists and is valid
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'An unknown error occurred!'; 
    if (error.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${error.error.message}`;
    } else {
      errorMessage = `Server returned code: ${error.status}, error message is: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
